<!--
 功能名称:千里眼平台-技师排名
 开发人:梁高权
 创建时间:2021/03/25
 最后修改时间:2021/03/25
-->
<template>
  <div class="ranking-back">
    <tab-header :headerList="boss.techHeader" :currentTab="2" @changeShowType="changeRankingType" @changeTabPage="changeTabPage"></tab-header>
    <ranking-type-select :showRankingTypeSelect="showRankingTypeSelect" @changeRankingType="changeRankingType"></ranking-type-select>
    <time-select @timeChange="timeChange" :classType="'tech-ranking'"></time-select>
    <top-three :clockRanking="clockRanking"></top-three>
    <technician-ranking-list :clockRanking="clockRanking"></technician-ranking-list>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import table from '@/api/table'
import util from '@/utils/util'
export default {
  name:"technician-ranking",
  data(){
    return{
      timeParams:null,
      clockRanking:null,
      showRankingTypeSelect:true
    }
  },
  computed:{
    ...mapState(['boss','userStatus'])
  },
  components:{
    'tab-header':() => import('@/components/tab-header'),
    'ranking-type-select':() => import('./ranking-type-select'),
    'time-select':() => import('@/components/time-select'),
    'top-three':() => import('./top-three'),
    'technician-ranking-list':() => import('./technician-ranking-list')
  },
  watch:{
    'boss.techRankingType':{
      handler(newVal,oldVal){
        let {timeParams} = this
        if(! newVal || ! timeParams){
          return
        }
        if(! ! newVal && ! ! oldVal && newVal === oldVal){
          return
        }
        this.getTechnicianRanking()
      },
      immediate:true,
      deep:true
    },
    timeParams:{
      handler(newVal,oldVal){
        let {timeParams} = this
        if(! newVal || ! timeParams){
          return
        }
        if(! ! newVal && ! ! oldVal && newVal.startTime === oldVal.startTime){
          return
        }
        this.getTechnicianRanking()
      },
      immediate:true,
      deep:true
    },
    'userStatus.curs_node_id_2':{
      handler(){
        this.getTechnicianRanking()
      },
      immediate:true,
      deep:true
    }
  },
  methods:{
    changeRankingType(value){
      // console.lol
      this.showRankingTypeSelect = !value
    },
    changeTabPage(index){
      this.$emit("changeTab",index)
      this.currentTab = index
    },
    /**
     *  获取技师钟数排行榜 (添加防抖处理)
     */
    getTechnicianRanking:util.debounce(function(){
      let {boss:{techRankingType},timeParams} = this
      console.log(techRankingType)
      let {startTime,endTime} = timeParams
              let timeType = !!timeParams?timeParams.type:1
      let params = {
        rankingType:techRankingType,
        timeType,
        startTime,
        endTime
      }
      return new Promise((resolve,reject) =>{
        table.getTechnicianRanking(params).then(res =>{
          this.clockRanking = res.data
          resolve()
        }).catch(err =>{
          console.log('err',err.msg)
          reject()
        })
      })
    },300),
    /* 切换时间段*/
    timeChange(timeParams){
      this.timeParams = timeParams
    }
  }
}
</script>

<style lang="scss" type="text/scss" scoped>
@import "~@/assets/style/public.scss";
.ranking-back{
    flex:1;
    background:url("~@/assets/images/ranking-back.png") no-repeat center -62px;
    background-size:100% auto;
    display:flex;
    flex-direction:column;

    // top:-2px;
}
</style>
